<template>
  <div style="padding: 2rem 2rem 10rem 2rem">
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-md-between mb-2">
      <div class="d-flex align-items-center">
        <img
          :src="require('@/assets/images/logo/logo.png')"
          alt="RATE DCKK LOGO"
          style="width: 80px"
          class="mr-50"
        />
        <h1 class="font-weight-bolder mb-0">RATE DCKK</h1>
      </div>
      <div class="mt-1">
        <b-button
          v-if="$can('manage', 'All')"
          class="ml-50"
          size="sm"
          variant="primary"
          :to="{ name: 'dashboard' }"
          >Dashboard</b-button
        >
        <b-button
          class="ml-50"
          size="sm"
          variant="danger"
          @click="logout"
          >ออกจากระบบ</b-button
        >
      </div>
    </div>
    <div class="mx-auto">
      <b-card
        no-body
        class="mb-50"
      >
        <b-input-group>
          <b-form-input
            v-model="search"
            placeholder="ค้นหารถ"
            @keydown.enter.native="onClickSearch"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="onClickSearch"
            >
              ค้นหา
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card>
      <table-bt-widget
        :rows="items"
        :columns="columns"
        :loading="loading"
        :show-search="false"
        :show-add-button="false"
        view-able
        title="ผลการค้นหา"
        @clickView="onClickView"
      />
    </div>
  </div>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      search: '',
      columns: [
        {
          key: 'car_model',
          label: 'รุ่น',
          tdClass: 'text-center font-weight-bolder',
          thClass: 'text-center font-weight-bolder',
        },
        {
          key: 'car_name_en',
          label: 'ชื่อรุ่นอังกฤษ',
          tdClass: 'text-center font-weight-bolder',
          thClass: 'text-center font-weight-bolder',
        },
        {
          key: 'car_name_th',
          label: 'ชื่อรุ่นไทย',
          tdClass: 'text-center font-weight-bolder',
          thClass: 'text-center font-weight-bolder',
        },
        {
          key: 'selling_price',
          label: 'ราคา',
          tdClass: 'text-center font-weight-bolder',
          thClass: 'text-center font-weight-bolder',
          formatter: value => this.gFormatNumberToCurrency(value),
        },
      ],
      items: [],
      loading: false,
    }
  },
  methods: {
    async onClickSearch() {
      if (!this.search) {
        this.items = []
        return
      }
      this.loading = true
      const resp = await this.$http.get(`api/v1/car/search/${this.search}`).catch(() => null)
      // console.log('onClickSearch', resp)
      if (resp && resp.code === 200) {
        this.items = [...resp.data]
      }
      this.loading = false
    },
    onClickView({ item }) {
      // console.log('onClickView', item)
      this.$router.push({
        name: 'rate-dckk-sale-view-show-rate',
        params: { id: item.id },
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss" scoped></style>
